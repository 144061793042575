import './HomePage.css'
import { useEffect, useState } from 'react';
import { memo } from 'react';
import Header from './components/Header';
import IntroContainer from './components/IntroContainer';
import VideoContainer from './components/VideoContainer';
import CanvasContainer from './components/CanvasContainer';
import BottleSpecificationContainer from './components/BottleSpecificationContainer';
import OurStoryContainer from './components/OurStoryContainer';
import ProductContainer from './components/ProductContainer';
import { useProductContext } from '../../components/products/ProductContext';
import ProductShell from '../../components/products/ProductShell';
import { useNavigate } from 'react-router-dom';




/**
 * 
 * @param {updateNav} Whether the nav should be white or transparent
 * @param {updateFooter} Whether the footer includes the contact form or not
 * @returns 
 */

const HomePage  = ({ updateFooter, language, routerLang, currentCountry}) => {
  const { products } = useProductContext(); // Access products from context
  const variants = products?.variants?.edges || [];
  const navigate = useNavigate();


  useEffect(() => {
    updateFooter(true);
  }, [ updateFooter]);
  
    // Listen to the scroll event and call the function
  
    return(
            <div className='home-container'>    
              <Header language={language} currentCountry={currentCountry}/>
              {currentCountry == 'fr' ? (
                <ProductContainer title={language('home.productpage.title')}>
                    {variants.map((variantEdge) => (
                        <ProductShell
                            key={variantEdge.node.id}
                            onClick={() => navigate(`/${routerLang}/productpage/${variantEdge.node.title}`)}
                            imageUrl={variantEdge.node.image?.originalSrc || '/default-image.jpg'}
                            title={language(`product.${variantEdge.node.title}`)}
                            description={variantEdge.description}
                            price={variantEdge.node.priceV2.amount + ' ' + variantEdge.node.priceV2.currencyCode}
                        />
                    ))}
                </ProductContainer>

              ) : (
                <IntroContainer language={language} />
              )}
              <VideoContainer language={language}/>
              <CanvasContainer/>
              <BottleSpecificationContainer language={language}/>
              <OurStoryContainer language={language} routerLang={routerLang}/>
            </div>
    ) 
}

export default memo(HomePage);
