
import './Loader.css'
import { lineWobble } from 'ldrs'


lineWobble.register()
const Loader = () => (
  <div className="loaderContainer">
    <img src="logoColor.png" alt="Logo"/>
    <l-line-wobble
      size="155"
      speed="3"
      color="#15A19C"
      style={styles.orbit}
    />
  </div>
);

const styles = {
    orbit: {
      paddingTop: '10px', // Add space between the image and the loader
    }
  };
  

export default Loader;

