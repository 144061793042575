import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import Footer from './components/footer/Footer';
import './App.css';
import Navbar from './components/navbar/Navbar';
import HomePage from './pages/HomePage/HomePage';
import ContactPage from './pages/ContactPage/ContactPage'
import { useTranslation } from 'react-i18next';
import './backend/language/i18n'
import OrdersPage from './pages/OrderPage/OrdersPage';
import LanguageChooser from './components/language/LanguageChooser';
import {useCookies} from 'react-cookie';
import CookieConsentForm from './components/cookieConsentForm/CookieConsentForm';
import Privacy from './pages/Privacy/Privacy';
import WrongUrl from './pages/WrongUrl/WrongUrl';
import ProductList from './backend/shopify/ProductList';
import ProductView from './pages/ProductPage/ProductView';
import Infobar from './components/navbar/Infobar';
import {ProductProvider} from './components/products/ProductContext';
import TopBar from './components/language/TopBar';
import useLanguageSwitcher from './components/language/UseLanguageSwitcher';

function App() {
  var { lang } = useParams();
  const history = useNavigate();
  const {t, i18n} = useTranslation();
  const memoizedLanguage = useMemo(() => t, [t]);
    const { changeLanguageAndRedirect } = useLanguageSwitcher();
  const location = useLocation();
  const [dynamicNavColor, setDynamicNavColor] = useState(false);
  const [dynamicFooter, setFooter] = useState(true);
  const [chosenLanguage, setChosenLanguage] = useState(true);
  const [selectedLang, setSelectedLang] = useState('EN');
  const [currentCountry, setCurrentCountry] = useState();
  const [hasCookies, setHasCookies] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies();

  /*
  useEffect(() => {
    let languageToUse = lang || navigator.language.split('-')[0]; // Fallback to browser language

    if (!cookies.bxh_appr) {
      setChosenLanguage(false);
      if (languageToUse === "sv" || languageToUse === "en") {
        changeLanguageAndRedirect(languageToUse);
      } else {
        changeLanguageAndRedirect("en");
      }
    } else if (lang === "sv" || lang === "en") {
      changeLanguageAndRedirect(lang);
      setChosenLanguage(true);
    } else if (!cookies.bxh_lang) {
      setChosenLanguage(false);
      changeLanguageAndRedirect("en");
    } else {
      languageToUse = cookies.bxh_lang;
      changeLanguageAndRedirect(languageToUse);
    }

    setSelectedLang(languageToUse);
  }, [lang, cookies]);

  */

  const setLanguage = (region) => {

    if(lang === 'sv' || lang === 'en'){
      changeLanguageAndRedirect(lang)
      setSelectedLang(lang)
    }
    else if (region == 'SE'){
      if (cookies.bxh_lang === 'en'){
        changeLanguageAndRedirect('en')
        setSelectedLang('en')
      }
      changeLanguageAndRedirect('sv')
      setSelectedLang('sv')
    }
    else{
      changeLanguageAndRedirect('en')
      setSelectedLang('en')
    }
  }
  
  useEffect (() => {
    // read cookie with 
    if(cookies.bxh_appr == '1'){
      setHasCookies(true);
    }
    else{
      setTimeout(() => {
        setHasCookies(false);
      }, 500); 
    }
  }, [])

  useEffect(() => {
    fetch('https://ipapi.co/json/')
    .then( res => res.json())
    .then(response => {
      setCurrentCountry(response.country_code);
      setLanguage(response.country_code);
    })
    .catch((data, status) => {
      setCurrentCountry('US')
    });
  },[])

  // Memoize the updateFooter function
  const updateFooter = useCallback((state) => {
    setFooter(state);
  }, []);

  return (
    <body>
      <ProductProvider>
      <link href='https://fonts.googleapis.com/css?family=Ubuntu:300,400,500' rel='stylesheet'></link>
      <TopBar setChosenLanguage={setChosenLanguage} currentLang={selectedLang} currentCountry={currentCountry} setCurrentCountry={setCurrentCountry} language={t}/>
      <Navbar dynamicNavColor={dynamicNavColor} language={t} routerLang={lang} chosenLanguage={chosenLanguage} currentCountry={currentCountry}/>
      <Infobar routerLang={lang}/>
      {!hasCookies && (
            <div className="cookie-overlay">
                <CookieConsentForm setHasCookies={setHasCookies} language={t} routerLang={lang}/>
            </div>
        )}
      <ScrollToTop/>
        <Routes location={location} key={location.pathname}>  
          <Route path="/" element= {<HomePage updateFooter= {updateFooter} language={memoizedLanguage} routerLang={lang} currentCountry={currentCountry}/>}/>
          <Route path="/test" element= {<ProductList/>}/>
          {/*currentCountry === 'SE' &&
            <Route path="/productpage/:color" element= {<ProductView language={memoizedLanguage}/>}/>
      */}
          <Route path="/orders" element={<OrdersPage updateFooter= {updateFooter}  language={memoizedLanguage}/>}/>
          <Route path="/contact" element={<ContactPage updateFooter= {updateFooter}  language={memoizedLanguage}/>}/>
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/*" element={<WrongUrl updateFooter= {updateFooter} language={memoizedLanguage}/>} />
        </Routes>
      <Footer dynamicFooter={dynamicFooter} language={t} routerLang={lang} cookieVar={setHasCookies}/>
      </ProductProvider>
    </body>
    )
  //)  
}


export default App;
