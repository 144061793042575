import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(10%);
  }
`;

const Text = styled.p`
  margin-top: 0;
  color: black;
  font-size: 16px;
  padding-top: 0;
  text-align: center;
  animation: ${(props) => (props.fade ? slideIn : slideOut)} 0.5s forwards;
`;

const Infobar = ({routerLang}) => {
  const textSV = ['Kontakta oss för skräddarsydda flaskor', 'Nu på Amazon! 😄', 'Livstids garanti!'];
  const textEN = ['Contact us for special bottles', 'Now on Amazon! 😄', 'Lifetime guarantee'];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % textSV.length);
        setFade(true);
      }, 500); // Duration of the fade-out effect
    }, 5000);

    return () => clearInterval(interval);
  }, [textSV.length]);

  return (
    <div style={{ height: '30px', paddingTop: '15px', paddingBottom:'10px', backgroundColor: '#f2f2f2' }}>
      <Text fade={fade}>
      {routerLang === 'sv' ? textSV[currentTextIndex] : textEN[currentTextIndex]}
      </Text>
    </div>
  );
};

export default Infobar;