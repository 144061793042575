import { useState, memo } from "react";
import { Blurhash } from "react-blurhash";
import '../OrdersPage.css';

const Header = ({ language }) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  const handleImageLoad = () => {
    setImgLoaded(true);
  };

  return (
    <>
      <div className="image-container" style={{ position: "relative", width: "100vw", maxHeight: "1100px", height: "650px" }}>
        {!imgLoaded && (
          <Blurhash
            hash="L59QgS0-L#DjG=RPm9TI00}pm^.Q"
            width="100%"
            height="100%"
            resolutionX={32}
            resolutionY={32}
            punch={1}
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          />
        )}
        <img
          src="/bxhaled9.jpg"
          alt="Image with overlay"
          className="image"
          onLoad={handleImageLoad}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            display: imgLoaded ? "block" : "none",
            position: "relative",
            zIndex: 1,
          }}
        />
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        ></div>
      </div>
      <div className="order-info-container" style={{ zIndex: 1 }}>
        <h1>{language('orders.title')}</h1>
        <h4 style={{ fontSize: "24px" }}>{language('orders.description')}</h4>
      </div>
    </>
  );
};

export default memo(Header);